import React from 'react';
import "./HeaderDecription.css"

function HeaderDecription() {

  return (

    <div className='HeaderDecription'>
      <h1 className="purpose">Tiegrun - Web Developer</h1>

      <h2 className="purpose">My Projects</h2>
      <h3 className="purpose">Portfolio Showcase</h3>
      <p className="purpose">Explore my diverse range of projects, from dynamic web applications to innovative user interfaces that highlight my coding skills.</p>
      <h4 className="purpose">View My Work</h4>
      <p className="purpose">Check out my latest projects on GitHub and see how I bring ideas to life through code.</p>

      <h3 className="purpose">Open Source Contributions</h3>
      <p className="purpose">Contributing to the open-source community, collaborating with other developers to create impactful solutions.</p>

      <h2 className="purpose">Skills and Expertise</h2>
      <h3 className="purpose">Front-End Development</h3>
      <p className="purpose">Specializing in HTML, CSS, and JavaScript to create responsive and engaging user experiences.</p>
      <h3 className="purpose">Back-End Development</h3>
      <p className="purpose">Proficient in Node.js and Express for building robust server-side applications.</p>

      <h2 className="purpose">Web Development Services</h2>
      <h3 className="purpose">Custom Web Solutions</h3>
      <p className="purpose">Tailored web development services to meet your business needs, from concept to deployment.</p>

      <h3 className="purpose">Consulting and Strategy</h3>
      <p className="purpose">Offering expert advice on web development best practices and technology choices to enhance your digital presence.</p>

      <h2 className="purpose">Client Testimonials</h2>
      <h3 className="purpose">What Clients Are Saying</h3>
      <p className="purpose">"Tigran delivered exceptional results, transforming our website into a user-friendly experience!"</p>
      <h3 className="purpose">Feedback on Web Solutions</h3>
      <p className="purpose">"Highly recommended for his professionalism and dedication to quality work!"</p>

      <h2 className="purpose">Learning and Growth</h2>
      <h3 className="purpose">Continuous Improvement</h3>
      <p className="purpose">I believe in lifelong learning, regularly exploring new technologies and frameworks to stay ahead in the web development field.</p>

      <h2 className="purpose">Contact Me</h2>
      <h3 className="purpose">Let's Collaborate</h3>
      <p className="purpose">Reach out for inquiries, collaborations, or to discuss potential projects. I'm excited to connect!</p>

    </div>
  )
}

export default HeaderDecription