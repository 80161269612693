import React, {useState, useEffect, Fragment} from 'react';
import "./Footer.css";
import {mapStateToProps, mapDispatchToProps} from '../../Store/storeProps';
import {connect} from "react-redux";

function Footer() {

  const [year, setYear] = useState(2022);
  const [companyName, setCompanyName] = useState("");
  const [secondCompanyName, setSecondCompanyName] = useState("");

  useEffect(()=>{

    const date = new Date();
    const year = date.getFullYear();
    setYear(year);
    setCompanyName("Tiegrun")
    setSecondCompanyName("It's Designed By Me")

  }, [])

  return (
    
    <Fragment>
      
      <footer className="Footer dfcja">
        <small className="copyRight">
          &copy; <a href="https://www.tieg.run/">{companyName}</a> | <a href="https://itsdesignedby.me/">{secondCompanyName}</a> - {year}
        </small>
      </footer>

    </Fragment> 
  )
}

export default connect(mapStateToProps, mapDispatchToProps)(Footer)
