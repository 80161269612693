const initialSocialState = {
  isDarkMainLogo: false,
}

const styleChangerReducer = (state = initialSocialState, action)=>{
  
  switch(action.type){
    
    case "MAINLOGODARK":
      return {
        ...state,
        isDarkMainLogo: action.payload
      }

    default:
      return state
  }
}

export default styleChangerReducer