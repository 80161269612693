const ISRIGHTPASSWORD = "ISRIGHTPASSWORD";

const isRightPass = (check) => {
  return {
    type: ISRIGHTPASSWORD,
    payload: check
  }
}

export const isRightPassAsync = (check) => dispatch =>{

  dispatch(isRightPass(check))
}