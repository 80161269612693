import { combineReducers } from 'redux';
import socialReducer from './getSocialMedia';
import lockReducer from "./lockCondition";
import passReducer from "./rightPassword";
import styleChangerReducer from "./styleChanges";
import screenSizeReducer from "./changedScreenSize"

export default combineReducers({
  socialReducer,
  lockReducer,
  passReducer,
  styleChangerReducer,
  screenSizeReducer
})