const initialSocialState = {
  isLocked: false,
}

const lockReducer = (state = initialSocialState, action)=>{
  
  switch(action.type){
    
    case "ISLOCKED":
      return {
        ...state,
        isLocked: action.payload
      }

    default:
      return state
  }
}

export default lockReducer