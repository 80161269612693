import React, {Fragment} from 'react';
import {mapStateToProps, mapDispatchToProps} from '../../Store/storeProps';
import {connect} from "react-redux";

function MainIntro() {

  return (
   
    <Fragment>
      
      <div className="mainIntro dfrja">
        <h5>
          The website is not designed to entertain visitors. 
          There are questions they think I can answer for them. 
          You can get the answers by unlocking the website.
        </h5>
      </div>
      
    </Fragment> 
  )
}

export default connect(mapStateToProps, mapDispatchToProps)(MainIntro)
