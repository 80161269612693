const MAINLOGODARK = "MAINLOGODARK";

const isDarkOrNormalMainLogo = (check) => {
  return {
    type: MAINLOGODARK,
    payload: check
  }
}

export const isDarkOrNormalMainLogoAsync = (check) => dispatch =>{

  dispatch(isDarkOrNormalMainLogo(check))
}