import React, {Fragment} from 'react';
import "./Main.css";
import "./MainLogo.css";
import "./MainInput.css";
import "./MainIntro.css";
import "./MainSocialMedia.css"; 
import MainInput from './MainInput';
import MainLogo from './MainLogo';
import MainSocialMedia from './MainSocialMedia';
import MainIntro from './MainIntro';
import {mapStateToProps, mapDispatchToProps} from '../../Store/storeProps';
import {connect} from "react-redux";

function Main() {

  return (

    <Fragment>
      <div className="Main">
        <MainLogo />
        <MainInput />
        <MainSocialMedia />
        <MainIntro />
      </div>
    </Fragment> 
  )
}

export default connect(mapStateToProps, mapDispatchToProps)(Main)
