import React, {Fragment} from 'react';
import PNG from "../../Images/Logo/PNG.png";
import {mapStateToProps, mapDispatchToProps} from '../../Store/storeProps';
import {connect} from "react-redux";

function MainLogo() {

  return (
    
    <Fragment>
      <div className="main_logo_dark dfrja">
        <div className="Tiegrun dfcja">
          TIEG
        </div>
        <div className="logo_Cont">
          <img className="logo" src={PNG} alt="logo" />
        </div>
        <div className="Tiegrun dfcja">
          RUN
        </div>
        </div>
    </Fragment>  
  )
}

export default connect(mapStateToProps, mapDispatchToProps)(MainLogo)
