import React, { useEffect } from 'react';

const SchemaMarkup = () => {
  useEffect(() => {

    const imageUrl = process.env.REACT_APP_TIEGRUN; 

    const schemaData = {
      "@context": "https://schema.org",
      "@type": "Web Developer",
      "name": "Tiegrun",
      "description": "A creative hub where I bring ideas to life through code. Become a part of my digital journey as I explore web development, personal insights, and the unique skills that define my digital presence.",
      "jobTitle": "Web Developer",
      "openingHours": "Mo,Tu,We,Th,Fr,Sa,Su 09:00-17:00",
      "telephone": "8105883313",
      "address": {
        "@type": "PostalAddress",
        "streetAddress": "West Harvard street",
        "addressLocality": "Glendale",
        "addressRegion": "CA",
        "postalCode": "91204",
        "addressCountry": "US"
      },
      "priceRange": "150-12000$$",
      "paymentAccepted": "Cash, Credit Card, Debit Card",
      "image": imageUrl,
      "email": "mailto:mycode.tiegrun@gmail.com",
    };

    // Create the JSON-LD script element
    const script = document.createElement('script');
    script.type = 'application/ld+json';
    script.innerHTML = JSON.stringify(schemaData);

    // Append the script to the head
    document.head.appendChild(script);

    // Cleanup function to remove the script on component unmount
    return () => {
      document.head.removeChild(script);
    };
  }, []); // Empty dependency array to run only on mount and unmount

  return null; // This component doesn't render anything visible
};

export default SchemaMarkup;
