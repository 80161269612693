import React, {Fragment} from 'react';
import PDF from '../Documents/Resume.pdf';

function Resume() {

  return (
    <Fragment>
      <object data={PDF} type="application/pdf" width="100%" height="100%">  
      </object>
    </Fragment> 
  )
}

export default Resume
