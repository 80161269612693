import React, {  useEffect, Fragment} from 'react';
import {mapStateToProps, mapDispatchToProps} from '../../Store/storeProps';
import {connect} from "react-redux";

function MainSocialMedia({isPassRight, socialList, getSocialListAsync, isDarkOrNormalMainLogoAsync}) {
  
  useEffect(() => {

    if(socialList === "Empty" && isPassRight){

      getSocialListAsync()
    }
  })

  const handleIN = ()=>{
  
     isDarkOrNormalMainLogoAsync(true);
  }

  const handleOut = ()=>{
    
    isDarkOrNormalMainLogoAsync(false)
 }

  return (
    
    <Fragment>
     
      <div className="socialPlace dfrja">
        {
          socialList !== "Empty" && isPassRight
          ?
            socialList.map(social=>{
              return(
                <div className="socialicons" key = {social.id} aftercontent={social.quote}>
                  <a href = {social.URL} target={social.target} rel="noreferrer noopener">
                    <i className = {social.class} onMouseEnter={handleIN} onMouseLeave={handleOut}></i>
                  </a> 
                </div>
              )
            })
          :
          <Fragment></Fragment>
        }
      </div>

    </Fragment> 
  )
}

export default connect(mapStateToProps, mapDispatchToProps)(MainSocialMedia)
