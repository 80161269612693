const initialSocialState = {
  isRightPass: false,
}

const passReducer = (state = initialSocialState, action)=>{
  
  switch(action.type){
    
    case "ISRIGHTPASSWORD":
      return {
        ...state,
        isRightPass: action.payload
      }

    default:
      return state
  }
}

export default passReducer