import React, { useState, Fragment, useEffect } from 'react';
import { _mainPassword as mainPassword}  from "../../DATA/_data";
import {mapStateToProps, mapDispatchToProps} from '../../Store/storeProps';
import {connect} from "react-redux";

function MainInput({isRightPassAsync, lockCondition, isScreenSize}) {

  const [inputValue, setInputValue] = useState('');
  const [disableInput, setDisableInput] = useState(false);
  const [showInput, setShowInput] = useState(true);

  useEffect(()=>{
    
    if(inputValue === mainPassword()){

      isRightPassAsync(true);

      setTimeout(()=>{
        setDisableInput(true);
        setInputValue("Correct !!!");
      }, 200)
    }
  })

  useEffect(()=>{

    if(disableInput){

      setTimeout(()=>{

        setShowInput(false)
      }, 1000)
    }
  },)

  const handleInput = (e) =>{

    const value = e.target.value;

    e.preventDefault();
    setInputValue(value)
  }

  return (

    <Fragment>
      {
        lockCondition && showInput
          ?
          <div className="passArea dfcja">
              <input type="text" className={!disableInput ? "pass" : "passCorrect"} maxLength="6" placeholder='******' value={inputValue}  disabled={disableInput} onChange={handleInput} autoFocus />
            </div>
          :
            <div className={showInput ? "noPassArea dfrja" : "noPassAreaAnimation dfrja"}>
            </div>
      }
    </Fragment>
  )
}

export default connect(mapStateToProps, mapDispatchToProps)(MainInput)
