import React from 'react';
import "../Styles/Err.css";
import { Link } from "react-router-dom";

function Err() {

  return (
    
    <div className='Err'>
      <p>
        Error 404: Page Not Found
      </p>
      <Link to="/">Go Home Page <i className="fas fa-house-user fa-2x"></i></Link>
    </div> 
  )
}

export default Err
