const ISCHANGEDSCREENSIZE = "ISCHANGEDSCREENSIZE";

const isChangedScreenSize = (check) => {
  return {
    type: ISCHANGEDSCREENSIZE,
    payload: check
  }
}

export const isChangedScreenSizeAsync = (check) => dispatch =>{

  dispatch(isChangedScreenSize(check))
}