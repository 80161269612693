const ISLOCKED = "ISLOCKED";

const isLocked = (check) => {
  return {
    type: ISLOCKED,
    payload: check
  }
}

export const isLockedAsync = (check) => dispatch =>{

  dispatch(isLocked(check))
}