const initialSocialState = {
  isChangedScreenSize: undefined,
}

const screenSizeReducer = (state = initialSocialState, action)=>{
  
  switch(action.type){
    
    case "ISCHANGEDSCREENSIZE":
      return {
        ...state,
        isChangedScreenSize: action.payload
      }

    default:
      return state
  }
}

export default screenSizeReducer