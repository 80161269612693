const initialSocialState = {
  socialList: [],
}

const socialReducer = (state = initialSocialState, action)=>{
  
  switch(action.type){
    
    case "GETSOCIALMEDIALIST":
      return {
        ...state, 
        socialList: action.payload
      };

    default:
      return state
  }
}

export default socialReducer