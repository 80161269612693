import {bindActionCreators} from "redux";
import actions from '../Actions'

export const mapStateToProps = (state) =>{

  

  const socialState = state.socialReducer;
  const lockState = state.lockReducer;
  const passState = state.passReducer;
  const styleChanger = state.styleChangerReducer;
  const storePass = state.storePassReducer;
  const screenSize = state.screenSizeReducer


  return{
    socialList: socialState.socialList.length === 0 ? "Empty" : socialState.socialList,
    lockCondition: lockState.isLocked,
    isPassRight: passState.isRightPass,
    isChangeMainLogoStyle: styleChanger.isDarkMainLogo,
    isStorePass: storePass,
    isScreenSize: screenSize,
  }
}

export const mapDispatchToProps = (dispatch) =>{
  return bindActionCreators(actions, dispatch);
}