let socials = {
  facebook: {
    id: 'facebook',
    class: 'fab fa-facebook fa-2x',
    URL: 'https://www.facebook.com/tiegrun',
    target: "_blank"
  },
  twitter: {
    id: 'twitter',
    class: 'fab fa-twitter fa-2x',
    URL: 'https://twitter.com/tiegrun',
    target: "_blank"
  },
  youtube: {
    id: 'youtube',
    class: 'fab fa-youtube fa-2x',
    URL: 'https://www.youtube.com/channel/UCQwlRg2lqD9k_UJnxVVkzlA',
    target: "_blank"
  },
  instagram: {
    id: 'instagram',
    class: 'fab fa-instagram fa-2x',
    URL: 'https://www.instagram.com/tiegrun/',
    target: "_blank"
  },
  linkedin: {
    id: 'likedin',
    class: 'fab fa-linkedin fa-2x',
    URL: 'https://www.linkedin.com/in/tiegrun/',
    target: "_blank"
  },
  github: {
    id: 'github',
    class: 'fab fa-github fa-2x',
    URL: 'https://github.com/tiegrun',
    target: "_blank"
  },
  stackoverflow: {
    id: 'stackoverflow',
    class: 'fab fa-stack-overflow fa-2x',
    URL: 'https://stackoverflow.com/users/13386685/tiegrun',
    target: "_blank"
  },
  telegram: {
    id: 'telegram',
    class: 'fa fa-paper-plane fa-2x',
    URL: 'https://t.me/Tiegrun',
    target: "_blank"
  },
  resume: {
    id: 'resume',
    class: 'fa fa-download fa-2x',
    URL: '/resume',
    target: "_blank"
  },
}

const _getSocials = ()=>{

  return new Promise((res, rej) => {
    
    setTimeout(() => res({...socials}), 1000)
  })
}

const _fistThreeDigits = (numA, numB, numC) =>{

  return numA*numB*numC
}

const _nextTwoDigits = (numD, numE) =>{

  return numD*numE
}

const _mainPassword = ()=>{

  let password = _fistThreeDigits(13,6,3).toString() + _nextTwoDigits(9,7).toString()

  return password + "9"
}

export {
  _getSocials,
  _mainPassword
}

