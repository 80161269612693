import {getSocialListAsync} from "./getSocialMedia";
import {isLockedAsync} from "./lockCondition";
import {isRightPassAsync} from "./rightPassword";
import {isDarkOrNormalMainLogoAsync} from "./styleChanges";
import {isChangedScreenSizeAsync} from "./changedScreenSize";
 
const actions = {
  getSocialListAsync, 
  isLockedAsync,
  isRightPassAsync,
  isDarkOrNormalMainLogoAsync, 
  isChangedScreenSizeAsync
}

export default actions;