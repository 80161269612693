import { _getSocials as getSocials}  from "../DATA/_data";

const GETSOCIALMEDIALIST = "GETSOCIALMEDIALIST";

const getSocialList = (socials) => {
  return {
    type: GETSOCIALMEDIALIST,
    payload: Object.values(socials)
  }
}

export const getSocialListAsync = () => dispatch =>{

  getSocials()
  .then(data => {
   
    const socialList = Object.values(data)

    dispatch(getSocialList(socialList))
   
  })
  .catch(er => {
    console.log(er)
  });
}